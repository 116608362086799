html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* [type=button]{
  -webkit-appearance: none !important;
} */

@font-face {
  font-family: 'Futura';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 400;
  src: url(/fonts/Futura_PT_Book.woff);
}

@font-face {
  font-family: 'Futura';
  font-style: 'bold';
  font-display: 'swap';
  font-weight: 700;
  src: url(/fonts/Futura_PT_Bold.woff);
}
